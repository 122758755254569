import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Header from './header';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import cssObj from '../../styles/cssObj';

const StyledBackgroundImageContainer = styled.div`
    position: fixed;
    z-index: 3;
    top: 0;
    right: 0;
    left: 0;

    @media (min-width: ${cssObj.vars.largeBreakPoint}) {
        max-height: 100vh;
        width: ${cssObj.vars.verticalHeaderWidth};
    }
`;

const StyledBackgroundImage = styled(GatsbyImage)`
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

const headerImageQuery = graphql`
    query {
        placeholderImage: file(
            relativePath: { eq: "personal/chicago-min.jpg" }
        ) {
            childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 2048)
            }
        }
    }
`;

const HeaderContainer = () => {
    const data = useStaticQuery(headerImageQuery);
    const image = getImage(data.placeholderImage);

    return (
        <StyledBackgroundImageContainer>
            <StyledBackgroundImage
                image={image}
                alt="Lithograph landscape of nineteenth-century Chicago"
                imgStyle={{
                    objectFit: 'cover',
                    objectPosition: 'center center'
                }}
            />
            <Header />
        </StyledBackgroundImageContainer>
    );
};

export default HeaderContainer;
