/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import './src/styles/normalize.css';
import './node_modules/prismjs/themes/prism-solarizedlight.css';
import './src/styles/style.css';
import 'typeface-noto-serif-jp';
import 'typeface-nunito-sans';

// Hopefully temporary code to pull in Bluesky embed code
export const onInitialClientRender = () => {
    const script = document.createElement('script');
    script.src = 'https://embed.bsky.app/static/embed.js';
    script.async = true;
    script.charset = 'utf-8';
    document.body.appendChild(script);
};

export const onRouteUpdate = () => {
    // Ensure the script has loaded and call window.bluesky.scan()
    if (window.bluesky && typeof window.bluesky.scan === 'function') {
        window.bluesky.scan();
    } else {
        console.warn('Bluesky embed script not loaded yet.');
    }
};
